<template><Icone/></template>

<script>
export default {
  name: 'IconePinceauGros',
  components: {
    Icone: () => import('./inline.IconePinceauGros.svg'),
  },
}
</script>
